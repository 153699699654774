.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  cursor: pointer;
  color: #fff;
  height: 400px;
}

.proj-imgbx img {
  width: 100%;
  object-fit: cover;
}

.proj-imgbx::before {
  content: '';
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 120%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding: 5px;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

@media (max-width: 1211px) {
  .proj-txtx h4 {
    font-size: 20px;
  }

  .proj-txtx span {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .proj-txtx h4 {
    font-size: 18px;
  }

  .proj-txtx span {
    font-size: 10px;
  }
}
