@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: CentraMedium;
  src: url('./assets/fonts/CentraNo2-Medium.ttf');
  font-weight: 700;
}

@font-face {
  font-family: CentraBook;
  src: url('./assets/fonts/CentraNo2-Book.ttf');
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p.success {
  color: green;
}
p.danger {
  color: red;
}
