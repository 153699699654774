.skill {
  padding: 0 0 50px;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 45px;
  font-weight: bold;
}

.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skills-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skills-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.stack {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
}

.stack img {
  width: 110px;
}

.background-image-left {
  position: absolute;
  top: 28%;
  bottom: 0;
  width: 40%;
  z-index: -4;
  filter: blur(44px);
}

@media (max-width: 448px) {
  .skill-bx .stack img {
    width: 50px;
  }
}
